import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { AbstractFormatter } from './abstract-formatter';

/**
 * Diretiva para formatação e validação de Telefone.
 */
@Directive({
  standalone: true,
  selector: '[telefone]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TelefoneDirective,
      multi: true,
    },
  ],
})
export class TelefoneDirective extends AbstractFormatter implements ControlValueAccessor {
  onTouched: any;

  onChange: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super();
  }

  /**
   * Padrão de formatação para 9 dígitos.
   */
  format9 = '(99) 99999-9999';

  /**
   * Padrão de formatação para 8 dígitos.
   */
  format8 = '(99) 9999-9999';

  @Input('formControl') form: UntypedFormControl;

  writeValue(value: any): void {
    const formatedValue = this.formatValue(
      value || '',
      value ? this.checkFormat(value) : this.checkFormat('')
    );
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', formatedValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    const clearedValue = this.clearValue($event.target.value);
    const formatedValue = this.formatValue(clearedValue, this.checkFormat(clearedValue));

    $event.target.value = formatedValue;
    this.onChange(this.clearValue(formatedValue));
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if ($event.target.value.length < this.format8.length) {
      $event.target.value = '';
      this.onChange('');
    }

    this.onTouched();
  }

  checkFormat(val = ''): string {
    if (val.length > 10) {
      return this.format9;
    }
    return this.format8;
  }
}
