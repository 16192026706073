import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { ProcessosService } from '@app/services/processos/processos.service';
import { RequestResponse } from '@app/shared/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  $userChange = new Subject<boolean>();

  constructor(private http: HttpClient, private processosService: ProcessosService) {}

  get isLoadedMenu() {
    return !!localStorage.getItem('menuList');
  }

  async loadMenu(): Promise<any> {
    if (this.isLoadedMenu) {
      return JSON.parse(localStorage.getItem('menuList'));
    }

    return this.fetchMenus();
  }

  async fetchMenus(): Promise<any> {
    const cliente = localStorage.getItem('user');

    if (cliente) {
      AppConfig.USER = JSON.parse(cliente);

      try {
        const menuList = (
          await this.http
            .get<RequestResponse<any>>(`${AppConfig.API_URL_CADASTRO}/menu/${environment.TP_MENU}`)
            .toPromise()
        ).data;

        if (menuList && menuList.length > 0) {
          localStorage.setItem('menuList', JSON.stringify(menuList));
        }

        return menuList;
      } catch (_) {
        return [];
      }
    } else {
      return [];
    }
  }

  resetMenu(): void {
    localStorage.removeItem('menuList');
  }
}
