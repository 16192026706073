import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

export interface ModalParams {
  title: string;
  content: string[];
  type: 'info' | 'success' | 'alert' | 'error' | 'question';
  buttons: {
    text: string;
    value: boolean;
    color?: 'primary' | 'accent' | 'warn';
  }[];
}
/**
 * Dialog de confirmação.
 *
 * Ex:
 *
 * {
 *  title: 'Deseja continuar?',
 *  content: ['Ao continuar, não poderá ser revertido'],
 *  buttons: [{
 *    text: 'CANCELAR',
 *    value: false,
 *    color: ''
 *  },
 * {
 *    text: 'CONFIRMAR',
 *    value: true,
 *    color: 'primary'
 *  }]
 * }
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  /**
   * Construtor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalParams,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this.dialogRef.disableClose = true;
  }

  getClass() {
    return {
      info: this.data.type === 'info',
      success: this.data.type === 'success',
      alert: this.data.type === 'alert',
      error: this.data.type === 'error',
      question: this.data.type === 'question',
    };
  }

  getIcon() {
    const icons = {
      info: 'info',
      success: 'check_circle',
      alert: 'warning',
      error: 'error',
      question: 'help',
    };

    return icons[this.data.type];
  }
}
